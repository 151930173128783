import React from 'react';
import { CheckOutlined, GlobalOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { onLocaleChange } from '../../redux/actions/theme';

const lang = [
    {
        'langName': 'English',
        'langId': 'en',
        'icon': 'us',
        'lang': 'En'
    },
    {
        'langName': 'Romanian',
        'langId': 'ro',
        'icon': 'ro',
        'lang': 'Ro'
    }
];

export const NavLanguage = ({ locale, onLocaleChange }) => {
    const languageOption = (
        <Menu>
            {
                lang.map((elm, i) => {
                    return (
                        <Menu.Item
                            key={i}
                            className={locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}
                            onClick={() => onLocaleChange(elm.langId)}
                        >
                            <span className="d-flex justify-content-between align-items-center">
                                <div>
                                    <img style={{ maxWidth: '20px' }} src={`/img/flags/${elm.icon}.png`} alt={elm.langName} />
                                    <span className="font-weight-normal ml-2">{elm.langName}</span>
                                </div>
                                {locale === elm.langId ? <CheckOutlined className="text-success" /> : null}
                            </span>
                        </Menu.Item>
                    );
                })
            }
        </Menu>
    );

    return (
        <Dropdown placement="bottomRight" overlay={languageOption} trigger={['click']}>
            <Menu mode='horizontal' style={{ borderBottom: 0 }}>
                <Menu.Item>
                    <a href='#/' onClick={e => e.preventDefault()}>
                        <GlobalOutlined className='nav-icon mr-0' />
                    </a>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
};

const mapStateToProps = ({ theme }) => {
    const { locale } = theme;
    return { locale };
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
