import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Loading from '../../components/shared-components/Loading';
import { APP_PREFIX_PATH } from '../../configs/app.config';

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboards`))} />
                <Route path={`${APP_PREFIX_PATH}/devices`} component={lazy(() => import(`./devices`))} />
                <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
                <Route path={`${APP_PREFIX_PATH}/blank`} component={lazy(() => import(`./blank`))} />
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
            </Switch>
        </Suspense>
    );
};

export default React.memo(AppViews);
