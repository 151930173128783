import history from '../../history';

import {
    AUTHENTICATED,
    REFRESH_TOKEN_SUCCESS,
    SIGNOUT_SUCCESS,
    SHOW_LOADING,
    HIDE_LOADING
} from '../constants/auth';

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    user: {
        token: false
    }
};

const auth = (state = initState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                loading: false,
                redirect: '/',
                user: action.user
            };
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                redirect: history.location.pathname,
                user: action.user
            };
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                user: {
                    token: false
                },
                redirect: '/',
                loading: false
            };
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case HIDE_LOADING: {
            return {
                ...state,
                loading: false
            };
        }
        default:
            return state;
    }
};

export default auth;
