import {
    SIGNIN,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    AUTHENTICATED,
    SIGNOUT,
    SIGNOUT_SUCCESS,
    SHOW_LOADING,
    HIDE_LOADING
} from '../constants/auth';

export const signIn = user => {
    return {
        type: SIGNIN,
        payload: user
    };
};

export const authenticated = user => {
    return {
        type: AUTHENTICATED,
        user
    };
};

export const refreshToken = token => {
    return {
        type: REFRESH_TOKEN,
        payload: token
    };
};

export const refreshTokenSuccess = user => {
    return {
        type: REFRESH_TOKEN_SUCCESS,
        user
    };
};

export const signOut = () => {
    return {
        type: SIGNOUT
    };
};

export const signOutSuccess = () => {
    return {
        type: SIGNOUT_SUCCESS
    };
};

export const showLoading = () => {
    return {
        type: SHOW_LOADING
    };
};

export const hideLoading = () => {
    return {
        type: HIDE_LOADING
    };
};
