import api from '../auth/interceptor';

import { REFRESH_TOKEN } from '../redux/constants/auth';

const AuthService = {};

AuthService.signIn = async (email, password) => {
    return await api.post(
        '/login',
        { email, password },
        { headers: { 'public-request': true } }
    );
};

AuthService.refreshToken = async () => {
    return await api.post(
        '/refresh-token',
        { token: localStorage.getItem(REFRESH_TOKEN) },
        { headers: { 'public-request': true } }
    );
};

export default AuthService;
