import antdRoRO from 'antd/es/locale/ro_RO';
import roMsg from '../locales/ro_RO.json';

const RoLang = {
    antd: antdRoRO,
    locale: 'ro-RO',
    messages: {
        ...roMsg
    }
};
export default RoLang;
