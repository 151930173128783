import React from 'react';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { signOut } from '../../redux/actions/auth';

import IntlMessage from '../util-components/IntlMessage';

export const NavProfile = ({ signOut }) => {
    const fullName = useSelector(state => state.auth.user.fullName);
    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    <div className="pl-3">
                        <h4 className="mb-0">{fullName}</h4>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    <Menu.Item key='1' onClick={e => signOut()}>
                        <span>
                            <LogoutOutlined className="mr-3" />
                            <span className="font-weight-normal">
                                <IntlMessage id="Sign out" />
                            </span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );

    return (
        <Dropdown placement='bottomRight' overlay={profileMenu} trigger={['click']}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item>
                    <h5 className="pt-4">{fullName}</h5>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
};

export default connect(null, { signOut })(NavProfile);
