import React from 'react';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from '../../constants/theme.constants';
import { connect } from 'react-redux';
import utils from '../../utils';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
    const { navCollapsed, navType } = props;
    const isNavTop = navType === NAV_TYPE_TOP ? true : false;

    if (isMobile && !props.mobileLogo) {
        return 0;
    }
    if (isNavTop) {
        return 'auto';
    }
    if (navCollapsed) {
        return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
        return `${SIDE_NAV_WIDTH}px`;
    }
};

const getLogoDisplay = (isMobile, mobileLogo) => {
    return isMobile && !mobileLogo ? 'd-none' : 'logo';
};

export const Logo = props => {
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
    const { navCollapsed } = props;

    return (
        <div
            className={getLogoDisplay(isMobile, props.mobileLogo)}
            style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}>
            <img src="/img/others/sports-festival.png" height={navCollapsed ? '50' : '100'} width={navCollapsed ? '50' : '140'} alt='' />
        </div>
    );
};

const mapStateToProps = ({ theme }) => {
    const { navCollapsed, navType } = theme;
    return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
