import { combineReducers } from 'redux';

import theme from './theme';
import auth from './auth';

const reducers = combineReducers({
    theme,
    auth
});

export default reducers;
