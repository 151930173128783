import axios from 'axios';
import { notification } from 'antd';

import { API_BASE_URL } from '../configs/app.config';
import { REFRESH_TOKEN } from '../redux/constants/auth';

import store from '../redux/store/index';
import history from '../history';

import { refreshToken } from '../redux/actions/auth';

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
});

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(async config => {
    const state = store.getState();
    const jwtToken = state.auth.user.token;

    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
        const token = localStorage.getItem(REFRESH_TOKEN);

        if (!token) {
            history.push(ENTRY_ROUTE);
            window.location.reload();
        }
    }

    return config;
}, error => {
    notification.error({ message: 'Error' });

    Promise.reject(error);
});

// API respone interceptor
service.interceptors.response.use(response => {
    return response.data;
}, async error => {
    let notificationParam = { message: '' };
    const originalRequest = error.config;

    if (error.response.status === 401) {
        const { dispatch } = store;
        const token = localStorage.getItem(REFRESH_TOKEN);

        if (originalRequest.url === '/login') {
            notificationParam.message = 'Authentication failed';
            notificationParam.description = 'Invalid credentials';
        }

        if (token && originalRequest.url !== 'refresh-token') {
            await dispatch(refreshToken(token));
        }
    }

    if (error.response.status === 404) {
        notificationParam.message = 'Not Found';
    }

    if (error.response.status === 500) {
        notificationParam.message = 'Internal Server Error';
    }

    if (error.response.status === 508) {
        notificationParam.message = 'Time Out';
    }

    if (notificationParam.message !== '') {
        notification.error(notificationParam);
    }

    return Promise.reject(error);
});

export default service;
