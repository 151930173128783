import { all, fork, takeEvery, put, call } from 'redux-saga/effects';

import AuthService from '../../services/auth';
import { REFRESH_TOKEN, SIGNIN, SIGNOUT } from '../constants/auth';
import { hideLoading, showLoading, authenticated, signOutSuccess, refreshTokenSuccess } from '../actions/auth';

export function* signIn() {
    yield takeEvery(SIGNIN, function* ({ payload }) {
        const { email, password } = payload;

        try {
            const response = yield call(AuthService.signIn, email, password);

            if (response.user) {
                localStorage.setItem(REFRESH_TOKEN, response.user.refreshToken);

                yield put(authenticated(response.user));
            }
        } catch (err) {
            yield put(hideLoading());
        }
    });
}

export function* signOut() {
    yield takeEvery(SIGNOUT, function* () {
        localStorage.removeItem(REFRESH_TOKEN);
        yield put(signOutSuccess());
    });
}

export function* refreshToken() {
    yield takeEvery(REFRESH_TOKEN, function* ({ payload }) {
        const { token } = payload;

        try {
            yield put(showLoading());

            const response = yield call(AuthService.refreshToken, token);

            if (response.user) {
                yield put(refreshTokenSuccess(response.user));
                localStorage.setItem(REFRESH_TOKEN, response.user.refreshToken);

                yield put(hideLoading());
            }
        } catch (err) {
            localStorage.removeItem(REFRESH_TOKEN);
            yield put(signOutSuccess());
            yield put(hideLoading());
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(signIn),
        fork(signOut),
        fork(refreshToken)
    ]);
}
