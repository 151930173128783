import { DashboardOutlined, PlusCircleOutlined, DesktopOutlined, RobotOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from './app.config';
import { ROLE_DASHBOARD, ROLE_USERS, ROLE_DEVICES } from '../constants/roles.constants';

const navTree = [
    {
        key: 'nav',
        path: `${APP_PREFIX_PATH}`,
        title: 'Home',
        icon: PlusCircleOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'dashboard',
                path: `${APP_PREFIX_PATH}/dashboard`,
                title: 'Dashboard',
                icon: DashboardOutlined,
                breadcrumb: true,
                role: ROLE_DASHBOARD,
                submenu: []
            },
            {
                key: 'devices',
                path: `${APP_PREFIX_PATH}/devices`,
                title: 'Devices',
                icon: DesktopOutlined,
                breadcrumb: true,
                role: ROLE_DEVICES,
                submenu: []
            },
            {
                key: 'users',
                path: `${APP_PREFIX_PATH}/users`,
                title: 'Users',
                icon: RobotOutlined,
                breadcrumb: true,
                role: ROLE_USERS,
                submenu: []
            }
        ]
    }
];

const navigationConfig = [
    ...navTree
];

export default navigationConfig;
