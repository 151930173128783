import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from '../constants/theme.constants';

export const APP_NAME = 'Knee Bending';
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light'
};
