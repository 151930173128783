import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createLogger } from 'redux-logger';

import reducers from '../reducers';
import rootSaga from '../sagas/index';

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();

let middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
    middlewares = [...middlewares, loggerMiddleware];
}

function configureStore(preloadedState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(reducers, preloadedState, composeEnhancers(
        applyMiddleware(...middlewares)
    ));

    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

const store = configureStore();

export default store;

