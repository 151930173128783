import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import store from './redux/store';
import Containers from './containers';

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path="/" component={Containers} />
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
