import React from 'react';
import { connect } from 'react-redux';
import { Layout, Grid } from 'antd';

import utils from '../../utils';
import AppViews from '../../containers/app-views';
import SideNav from '../../components/layout/SideNav';
import HeaderNav from '../../components/layout/HeaderNav';
import navigationConfig from '../../configs/navigation.config';
import MobileNav from '../../components/layout/MobileNav';
import PageHeader from '../../components/layout/PageHeader';

import {
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
    NAV_TYPE_SIDE,
    NAV_TYPE_TOP
} from '../../constants/theme.constants';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType }) => {
    const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
    const screens = utils.getBreakPoint(useBreakpoint());
    const isMobile = !screens.includes('lg');
    const isNavTop = navType === NAV_TYPE_TOP;
    const isNavSide = navType === NAV_TYPE_SIDE;

    const getLayoutGutter = () => {
        if (isNavTop || isMobile) {
            return 0;
        }

        return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
    };

    return (
        <Layout>
            <HeaderNav isMobile={isMobile} />
            <Layout className="app-container">
                {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} /> : null}
                <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
                    <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
                        <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
                        <Content>
                            <AppViews />
                        </Content>
                    </div>
                </Layout>
            </Layout>
            {isMobile && <MobileNav />}
        </Layout>
    );
};

const mapStateToProps = ({ theme }) => {
    const { navCollapsed, navType, locale } = theme;

    return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
