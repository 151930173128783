import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './light-theme.css';

import store from './redux/store';
import { refreshToken } from './redux/actions/auth';
import { REFRESH_TOKEN } from './redux/constants/auth';

const setup = async () => {
    const token = localStorage.getItem(REFRESH_TOKEN);

    if (token) {
        const { dispatch } = store;
        await dispatch(refreshToken(token));
    }
};

setup();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
