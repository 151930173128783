import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConfigProvider, Spin } from 'antd';

import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from '../configs/app.config';
import AppLayout from '../layouts/app-layout';
import AuthLayout from '../layouts/auth-layout';
import AppLocale from '../lang';

function RouteInterceptor({ children, loading, isAuthenticated, roles, ...rest }) {
    if (loading || isAuthenticated === undefined) {
        return <Spin />;
    }

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isAuthenticated) {
                    return children;
                }

                return <Redirect to={{ pathname: AUTH_PREFIX_PATH, state: { from: location } }} />;
            }}
        />
    );
}

export const Containers = props => {
    const { loading, locale, user, location } = props;
    const currentAppLocale = AppLocale[locale];
    const token = user.token;

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={APP_PREFIX_PATH} />
                    </Route>
                    <Route path={AUTH_PREFIX_PATH}>
                        {!token && <AuthLayout />}
                        {token && <Redirect to={APP_PREFIX_PATH} />}
                    </Route>
                    <Route exact path="/app">
                        <Redirect to={`${APP_PREFIX_PATH}/dashboard`} />
                    </Route>
                    <RouteInterceptor roles={user.roles} path={APP_PREFIX_PATH} loading={loading} isAuthenticated={token}>
                        <AppLayout location={location} />
                    </RouteInterceptor>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
    );
};


const mapStateToProps = ({ theme, auth }) => {
    const { locale } = theme;
    const { user, loading } = auth;

    return { locale, user, loading };
};

export default withRouter(connect(mapStateToProps)(Containers));
